<template>
  <v-dialog v-model="isVisibleDeleteDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{
        $t("delete_confirmation_message")
      }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeForm">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
          $t("ok")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    isVisibleDeleteDialog: {
      get() {
        return this.$store.state.orders.isVisibleDeleteDialog;
      },
      set(value) {
        this.$store.commit("orders/IS_VISIBLE_DELETE_DIALOG", value);
      },
    },
  },
  methods: {
    closeForm() {
      this.$store.dispatch("orders/closeForm");
    },
    async deleteItemConfirm() {
      try {
        await this.$store.dispatch("orders/destroy");
        this.$store.dispatch("orders/closeForm");
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
